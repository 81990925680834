<template>
  <div class="mb-15" v-if="tenderData">
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h2 class="mt-10">{{ tenderData.nombre }}</h2>
      </v-col>
      <v-col cols="6">
        <div align="right">
          <h2 class="mt-10">{{ tenderData.claveLicitacion }}</h2>
        </div>
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>
      <v-col v-if="cardsData.length > 0" v-for="(card, idx) in cardsData"
             :key="idx" cols="4">
        <div class="card-steps">
          <div class="header-steps" style="">
            <h2>{{ card.name }}</h2>
          </div>

          <div v-if="card.create && idx === 0"
               class="button-edit">
            <router-link class="text-decoration-none"
                         :to="{ name: 'DetailMarketStudy', params: { id: id, marketId:card.fileId } }">
              <span class="text-center white--text">Editar</span>
            </router-link>
          </div>
          <div v-else-if="!card.create && idx === 0"
               class="button-create">
            <router-link class="text-decoration-none"
                         :to="{ name: 'CreateMarketStudy', params: { id: id, formatId:card.formatId } }">
              <span class="text-center white--text ">Crear</span>
            </router-link>
          </div>
          <div v-if="card.create && idx === 1"
               class="button-edit">
            <router-link class="text-decoration-none"
                         :to="{ name: 'DetailSufficiency', params: { id: id, marketId:card.fileId } }">
              <span class="text-center white--text">Editar</span>
            </router-link>
          </div>
          <div v-else-if="!card.create && idx === 1 && idx <= statusTender.length"
               class="button-create">
            <router-link class="text-decoration-none"
                         :to="{ name: 'CreateSufficiency', params: { id: id, formatId:card.formatId } }">
              <span class="text-center white--text ">Crear</span>
            </router-link>
          </div>
          <div v-if="card.create && idx === 2"
               class="button-edit">
            <router-link class="text-decoration-none"
                         :to="{ name: 'DetailBases', params: { id: id, marketId:card.fileId } }">
              <span class="text-center white--text">Editar</span>
            </router-link>
          </div>
          <div v-else-if="!card.create && idx === 2 && idx <= statusTender.length"
               class="button-create">
            <router-link class="text-decoration-none"
                         :to="{ name: 'CreateBases', params: { id: id, formatId:card.formatId } }">
              <span class="text-center white--text ">Crear</span>
            </router-link>
          </div>
          <div v-if="card.create && idx === 3"
               class="button-edit">
            <router-link class="text-decoration-none"
                         :to="{ name: 'DetailAnnexe', params: { id: id, marketId:card.fileId } }">
              <span class="text-center white--text">Editar</span>
            </router-link>
          </div>
          <div v-else-if="!card.create && idx === 3 && idx <= statusTender.length"
               class="button-create">
            <router-link class="text-decoration-none"
                         :to="{ name: 'CreateAnnexe', params: { id: id, formatId:card.formatId } }">
              <span class="text-center white--text ">Crear</span>
            </router-link>
          </div>
          <div v-if="card.create && idx === 4"
               class="button-edit">
            <router-link class="text-decoration-none"
                         :to="{ name: 'DetailDoubts', params: { id: id, marketId:card.fileId } }">
              <span class="text-center white--text">Editar</span>
            </router-link>
          </div>
          <div v-else-if="!card.create && idx === 4 && idx <= statusTender.length"
               class="button-create">
            <router-link class="text-decoration-none"
                         :to="{ name: 'CreateDoubts', params: { id: id, formatId:card.formatId } }">
              <span class="text-center white--text ">Crear</span>
            </router-link>
          </div>
          <div v-if="card.create && idx === 5"
               class="button-edit">
            <router-link class="text-decoration-none"
                         :to="{ name: 'DetailClarificationMeetings', params: { id: id, marketId:card.fileId } }">
              <span class="text-center white--text">Editar</span>
            </router-link>
          </div>
          <div v-else-if="!card.create && idx === 5 && idx <= statusTender.length"
               class="button-create">
            <router-link class="text-decoration-none"
                         :to="{ name: 'CreateClarificationMeetings', params: { id: id, formatId:card.formatId } }">
              <span class="text-center white--text ">Crear</span>
            </router-link>
          </div>
          <div v-if="card.create && idx === 6"
               class="button-edit">
            <router-link class="text-decoration-none"
                         :to="{ name: 'DetailOpeningProposals', params: { id: id, marketId:card.fileId } }">
              <span class="text-center white--text">Editar</span>
            </router-link>
          </div>
          <div v-else-if="!card.create && idx === 6 && idx <= statusTender.length"
               class="button-create">
            <router-link class="text-decoration-none"
                         :to="{ name: 'CreateOpeningProposals', params: { id: id, formatId:card.formatId } }">
              <span class="text-center white--text ">Crear</span>
            </router-link>
          </div>
          <div v-if="card.create && idx === 7"
               class="button-edit">
            <router-link class="text-decoration-none"
                         :to="{ name: 'DetailProposals', params: { id: id, marketId:card.fileId } }">
              <span class="text-center white--text">Editar</span>
            </router-link>
          </div>
          <div v-else-if="!card.create && idx === 7 && idx <= statusTender.length"
               class="button-create">
            <router-link class="text-decoration-none"
                         :to="{ name: 'CreateProposals', params: { id: id, formatId:card.formatId } }">
              <span class="text-center white--text ">Crear</span>
            </router-link>
          </div>
          <div v-if="card.create && idx === 8"
               class="button-edit">
            <router-link class="text-decoration-none"
                         :to="{ name: 'DetailOpinions', params: { id: id, marketId:card.fileId } }">
              <span class="text-center white--text">Editar</span>
            </router-link>
          </div>
          <div v-else-if="!card.create && idx === 8 && idx <= statusTender.length"
               class="button-create">
            <router-link class="text-decoration-none"
                         :to="{ name: 'CreateOpinions', params: { id: id, formatId:card.formatId } }">
              <span class="text-center white--text ">Crear</span>
            </router-link>
          </div>
          <div v-if="card.create && idx === 9"
               class="button-edit">
            <router-link class="text-decoration-none"
                         :to="{ name: 'DetailRulings', params: { id: id, marketId:card.fileId } }">
              <span class="text-center white--text">Editar</span>
            </router-link>
          </div>
          <div v-else-if="!card.create && idx === 9 && idx <= statusTender.length"
               class="button-create">
            <router-link class="text-decoration-none"
                         :to="{ name: 'CreateRulings', params: { id: id, formatId:card.formatId } }">
              <span class="text-center white--text ">Crear</span>
            </router-link>
          </div>
          <div v-if="statusFiance">
            <div v-if="card.create && idx === 10"
                 class="button-edit">
              <router-link class="text-decoration-none"
                           :to="{ name: 'DetailBonds', params: { id: id, marketId:card.fileId } }">
                <span class="text-center white--text">Editar</span>
              </router-link>
            </div>
            <div v-else-if="!card.create && idx === 10 && idx <= statusTender.length"
                 class="button-create">
              <router-link class="text-decoration-none"
                           :to="{ name: 'CreateBonds', params: { id: id, formatId:card.formatId } }">
                <span class="text-center white--text ">Crear</span>
              </router-link>
            </div>
            <div v-if="card.create && idx === 11"
                 class="button-edit">
              <router-link class="text-decoration-none"
                           :to="{ name: 'DetailContract', params: { id: id, marketId:card.fileId } }">
                <span class="text-center white--text">Editar</span>
              </router-link>
            </div>
            <div v-else-if="!card.create && idx === 11 && idx <= statusTender.length"
                 class="button-create">
              <router-link class="text-decoration-none"
                           :to="{ name: 'CreateContract', params: { id: id, formatId:card.formatId } }">
                <span class="text-center white--text ">Crear</span>
              </router-link>
            </div>
          </div>
          <div v-if="!statusFiance">
            <div v-if="card.create && idx === 10"
                 class="button-edit">
              <router-link class="text-decoration-none"
                           :to="{ name: 'DetailContract', params: { id: id, marketId:card.fileId } }">
                <span class="text-center white--text">Editar</span>
              </router-link>
            </div>
            <div v-else-if="!card.create && idx === 10 && idx <= statusTender.length"
                 class="button-create">
              <router-link class="text-decoration-none"
                           :to="{ name: 'CreateContract', params: { id: id, formatId:card.formatId } }">
                <span class="text-center white--text ">Crear</span>
              </router-link>
            </div>
          </div>
        </div>
      </v-col>

    </v-row>
  </div>

</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "DetailsCards",
  data() {
    return {
      id: null,
      tenderData: null,
      statusFiance: false,
      cardsData: [],
      statusTender: []
    };
  },
  async created() {
    this.id = this.$route.params.id;
    await this.findOne();
    await this.findCards();
    await this.getAllStatusTender();
  },
  methods: {
    ...mapActions('tenders', ['findOneTenders']),
    ...mapActions('formats', ['findAllCards']),
    ...mapActions('statusTender', ['getEstatusLicitacion']),
    async nextStep(card) {
      if (!card.create) {
        this.sweetAlertWarning('No se puede continuar porque no se ha completado el paso anterior');
      }
    },
    async findOne() {
      this.tenderData = await this.findOneTenders(this.id);
      if (!this.tenderData) {
        await this.$router.push('/licitaciones')
      }
    },
    async findCards() {
      this.cardsData = [];
      const response = await this.findAllCards(this.id);
      if (response.length === 11) {
        this.statusFiance = false;
      }
      if (response.length === 12) {
        this.statusFiance = true;
      }
      this.cardsData = response.filter(card => card.orden % 1 === 0);
    },
    async getAllStatusTender() {
      this.statusTender = [];
      const response = await this.getEstatusLicitacion({tenderId: this.id});
      this.statusTender = response;
    }
  }

}
</script>
<style scoped>
.card-steps {
  background-color: #EEEEEE;
  border-radius: 10px;
  border: 1px solid #B2B2B2;
  padding: 10px;
  position: relative
}

.card-steps .header-steps {
  padding-top: 50px;
  padding-bottom: 70px;
}


.card-steps .header-steps h2 {
  text-align: center;
  font-weight: 400
}

.card-steps .button-create {
  cursor: pointer;
  height: 42px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #01AB9C;
  border-bottom-left-radius: 10px;;
  border-bottom-right-radius: 10px;
  padding: 8px
}

.card-steps .button-edit {
  cursor: pointer;
  height: 42px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #414141;
  border-bottom-left-radius: 10px;;
  border-bottom-right-radius: 10px;
  padding: 8px
}

</style>

